import { SVGProps } from "react"
import { JSX } from "react/jsx-runtime"

export const LogoIcon = (
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) => (
  <svg
    width="201"
    height="30"
    viewBox="0 0 201 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clip-path="url(#clip0_5574_9347)">
      <path
        d="M0.625 5.625C0.625 2.89531 2.97239 0.625 5.94089 0.625H25.7438C28.7123 0.625 31.0597 2.89531 31.0597 5.625V24.375C31.0597 27.1047 28.7123 29.375 25.7438 29.375H5.94089C2.97239 29.375 0.625 27.1047 0.625 24.375V5.625Z"
        fill="#4AA9FF"
        stroke="#4AA9FF"
        stroke-width="1.25"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25.7438 1.17188H5.94089C3.34338 1.17188 1.23768 3.16561 1.23768 5.625V24.375C1.23768 26.8344 3.34338 28.8281 5.94089 28.8281H25.7438C28.3413 28.8281 30.447 26.8344 30.447 24.375V5.625C30.447 3.16561 28.3413 1.17188 25.7438 1.17188ZM5.94089 0C2.65983 0 0 2.5184 0 5.625V24.375C0 27.4816 2.65983 30 5.94089 30H25.7438C29.0249 30 31.6847 27.4816 31.6847 24.375V5.625C31.6847 2.5184 29.0249 0 25.7438 0H5.94089Z"
        fill="#4AA9FF"
      />
      <path
        d="M7.92139 7.49902H11.0899V10.499H14.2583V13.499H17.4268V16.499L14.2583 16.499V19.499L11.0899 19.499V22.499H7.92139V16.499L11.0899 16.499V13.499H7.92139V7.49902Z"
        fill="white"
      />
      <path
        d="M17.4268 19.499V16.499L20.5953 16.499V19.499L17.4268 19.499Z"
        fill="white"
      />
      <path d="M20.5953 19.499H23.7637V22.499H20.5953V19.499Z" fill="white" />
      <path
        d="M17.4268 19.499L14.2583 19.499V22.499H17.4268L17.4268 19.499Z"
        fill="white"
      />
      <path
        d="M20.5953 16.499L20.5953 13.499H17.4268L17.4268 10.499H14.2583V7.49902H20.5953V10.499H23.7637V16.499H20.5953Z"
        fill="white"
      />
      <path
        d="M49.3686 20.625L49.3537 14.7328L46.2942 19.5844H45.2248L42.1653 14.8453V20.625H39.9077V10.7812H41.8979L45.7892 16.8984L49.6211 10.7812H51.6113L51.6261 20.625H49.3686Z"
        fill="#4AA9FF"
      />
      <path
        d="M57.7733 20.7375C56.9317 20.7375 56.1742 20.5734 55.5009 20.2453C54.8375 19.9078 54.3177 19.4438 53.9414 18.8531C53.5652 18.2625 53.3771 17.5922 53.3771 16.8422C53.3771 16.0922 53.5652 15.4219 53.9414 14.8313C54.3177 14.2406 54.8375 13.7812 55.5009 13.4531C56.1742 13.1156 56.9317 12.9469 57.7733 12.9469C58.6149 12.9469 59.3674 13.1156 60.0308 13.4531C60.6942 13.7812 61.2141 14.2406 61.5903 14.8313C61.9666 15.4219 62.1547 16.0922 62.1547 16.8422C62.1547 17.5922 61.9666 18.2625 61.5903 18.8531C61.2141 19.4438 60.6942 19.9078 60.0308 20.2453C59.3674 20.5734 58.6149 20.7375 57.7733 20.7375ZM57.7733 18.9375C58.3674 18.9375 58.8526 18.75 59.2288 18.375C59.615 17.9906 59.8081 17.4797 59.8081 16.8422C59.8081 16.2047 59.615 15.6984 59.2288 15.3234C58.8526 14.9391 58.3674 14.7469 57.7733 14.7469C57.1792 14.7469 56.6891 14.9391 56.3029 15.3234C55.9168 15.6984 55.7237 16.2047 55.7237 16.8422C55.7237 17.4797 55.9168 17.9906 56.3029 18.375C56.6891 18.75 57.1792 18.9375 57.7733 18.9375Z"
        fill="#4AA9FF"
      />
      <path
        d="M72.5882 18.825V22.2563H70.4346V20.625H64.8205V22.2563H62.6669V18.825H62.9936C63.4887 18.8156 63.8303 18.5391 64.0184 17.9953C64.2066 17.4516 64.3254 16.6781 64.3749 15.675L64.4788 13.0594H71.4445V18.825H72.5882ZM66.469 15.8297C66.4393 16.5984 66.375 17.2266 66.276 17.7141C66.1869 18.2016 66.0136 18.5719 65.7561 18.825H69.1276V14.8594H66.5136L66.469 15.8297Z"
        fill="#4AA9FF"
      />
      <path
        d="M82.0516 13.0594L78.4425 21.0891C78.0761 21.9609 77.6207 22.575 77.0761 22.9313C76.5414 23.2875 75.8929 23.4656 75.1304 23.4656C74.7146 23.4656 74.3037 23.4047 73.8977 23.2828C73.4917 23.1609 73.16 22.9922 72.9026 22.7766L73.7492 21.2156C73.9274 21.3656 74.1304 21.4828 74.3581 21.5672C74.5958 21.6516 74.8284 21.6938 75.0562 21.6938C75.373 21.6938 75.6305 21.6188 75.8285 21.4688C76.0265 21.3281 76.2048 21.0891 76.3632 20.7516L76.3929 20.6813L72.9323 13.0594H75.3235L77.5662 18.1922L79.8237 13.0594H82.0516Z"
        fill="#4AA9FF"
      />
      <path
        d="M90.2674 13.0594V20.625H87.9505V14.8594H85.4256L85.3513 16.1953C85.3117 17.1891 85.2127 18.0141 85.0543 18.6703C84.8959 19.3266 84.6384 19.8422 84.282 20.2172C83.9255 20.5922 83.4304 20.7797 82.7967 20.7797C82.4601 20.7797 82.0591 20.7234 81.5937 20.6109L81.7125 18.8109C81.8709 18.8297 81.9848 18.8391 82.0541 18.8391C82.4007 18.8391 82.6581 18.7219 82.8264 18.4875C83.0047 18.2438 83.1235 17.9391 83.1829 17.5734C83.2423 17.1984 83.2869 16.7063 83.3166 16.0969L83.4354 13.0594H90.2674Z"
        fill="#4AA9FF"
      />
      <path
        d="M96.8175 15.4781C97.8571 15.4875 98.6542 15.7125 99.2087 16.1531C99.7632 16.5844 100.04 17.1984 100.04 17.9953C100.04 18.8297 99.7285 19.4813 99.1047 19.95C98.4908 20.4094 97.6244 20.6391 96.5056 20.6391L92.4955 20.625V13.0594H94.7976V15.4781H96.8175ZM96.2976 19.0781C96.7432 19.0875 97.0848 18.9984 97.3224 18.8109C97.5601 18.6234 97.6789 18.3422 97.6789 17.9672C97.6789 17.6016 97.5601 17.3344 97.3224 17.1656C97.0947 16.9969 96.7531 16.9078 96.2976 16.8984L94.7976 16.8844V19.0781H96.2976Z"
        fill="#4AA9FF"
      />
      <path
        d="M106.107 13.5797C106.869 13.5797 107.538 13.7297 108.112 14.0297C108.686 14.3203 109.127 14.7328 109.434 15.2672C109.751 15.8016 109.909 16.4203 109.909 17.1234C109.909 17.8453 109.721 18.4875 109.345 19.05C108.978 19.6031 108.464 20.0344 107.8 20.3438C107.137 20.6438 106.384 20.7937 105.543 20.7937C104.147 20.7937 103.048 20.3719 102.245 19.5281C101.453 18.675 101.057 17.4281 101.057 15.7875C101.057 14.2688 101.404 13.0594 102.097 12.1594C102.8 11.25 103.874 10.6547 105.32 10.3734L109.122 9.65625L109.434 11.5406L106.077 12.1734C105.166 12.3422 104.488 12.6234 104.043 13.0172C103.607 13.4109 103.349 13.9688 103.27 14.6906C103.617 14.3344 104.033 14.0625 104.518 13.875C105.003 13.6781 105.533 13.5797 106.107 13.5797ZM105.558 19.0922C106.152 19.0922 106.627 18.9094 106.983 18.5438C107.35 18.1781 107.533 17.7141 107.533 17.1516C107.533 16.5797 107.35 16.125 106.983 15.7875C106.627 15.45 106.152 15.2812 105.558 15.2812C104.954 15.2812 104.468 15.45 104.102 15.7875C103.736 16.125 103.552 16.5797 103.552 17.1516C103.552 17.7234 103.741 18.1922 104.117 18.5578C104.493 18.9141 104.973 19.0922 105.558 19.0922Z"
        fill="#4AA9FF"
      />
      <path
        d="M119.269 13.0594L115.66 21.0891C115.294 21.9609 114.838 22.575 114.294 22.9313C113.759 23.2875 113.11 23.4656 112.348 23.4656C111.932 23.4656 111.521 23.4047 111.115 23.2828C110.709 23.1609 110.378 22.9922 110.12 22.7766L110.967 21.2156C111.145 21.3656 111.348 21.4828 111.576 21.5672C111.813 21.6516 112.046 21.6938 112.274 21.6938C112.591 21.6938 112.848 21.6188 113.046 21.4688C113.244 21.3281 113.422 21.0891 113.581 20.7516L113.61 20.6813L110.15 13.0594H112.541L114.784 18.1922L117.041 13.0594H119.269Z"
        fill="#4AA9FF"
      />
      <path
        d="M124.957 20.625L123.205 18.3047L121.393 20.625H118.868L121.942 16.8141L118.972 13.0594H121.571L123.264 15.2672L125.002 13.0594H127.467L124.497 16.7578L127.586 20.625H124.957Z"
        fill="#4AA9FF"
      />
      <path
        d="M135.128 14.8594H131.029V20.625H128.727V13.0594H135.128V14.8594Z"
        fill="#4AA9FF"
      />
      <path
        d="M139.46 12.9469C140.698 12.9469 141.648 13.2281 142.312 13.7906C142.975 14.3438 143.307 15.1828 143.307 16.3078V20.625H141.138V19.6828C140.703 20.3859 139.891 20.7375 138.703 20.7375C138.089 20.7375 137.554 20.6391 137.099 20.4422C136.653 20.2453 136.311 19.9734 136.074 19.6266C135.836 19.2797 135.717 18.8859 135.717 18.4453C135.717 17.7422 135.995 17.1891 136.549 16.7859C137.113 16.3828 137.98 16.1813 139.148 16.1813H140.99C140.99 15.7031 140.836 15.3375 140.529 15.0844C140.222 14.8219 139.762 14.6906 139.148 14.6906C138.722 14.6906 138.302 14.7562 137.886 14.8875C137.48 15.0094 137.133 15.1781 136.846 15.3938L136.014 13.8609C136.45 13.5703 136.97 13.3453 137.574 13.1859C138.188 13.0266 138.816 12.9469 139.46 12.9469ZM139.282 19.2609C139.678 19.2609 140.029 19.1766 140.336 19.0078C140.643 18.8297 140.861 18.5719 140.99 18.2344V17.4609H139.401C138.45 17.4609 137.975 17.7563 137.975 18.3469C137.975 18.6281 138.089 18.8531 138.316 19.0219C138.554 19.1813 138.876 19.2609 139.282 19.2609Z"
        fill="#4AA9FF"
      />
      <path
        d="M152.983 13.0594V20.625H150.666V14.8594H148.141L148.067 16.1953C148.028 17.1891 147.929 18.0141 147.77 18.6703C147.612 19.3266 147.354 19.8422 146.998 20.2172C146.641 20.5922 146.146 20.7797 145.513 20.7797C145.176 20.7797 144.775 20.7234 144.31 20.6109L144.428 18.8109C144.587 18.8297 144.701 18.8391 144.77 18.8391C145.116 18.8391 145.374 18.7219 145.542 18.4875C145.72 18.2438 145.839 17.9391 145.899 17.5734C145.958 17.1984 146.003 16.7063 146.032 16.0969L146.151 13.0594H152.983Z"
        fill="#4AA9FF"
      />
      <path
        d="M161.924 14.8594H159.221V20.625H156.919V14.8594H154.216V13.0594H161.924V14.8594Z"
        fill="#4AA9FF"
      />
      <path
        d="M170.594 16.8703C170.594 16.8984 170.579 17.0953 170.549 17.4609H164.504C164.613 17.9297 164.871 18.3 165.277 18.5719C165.683 18.8438 166.187 18.9797 166.791 18.9797C167.207 18.9797 167.574 18.9234 167.891 18.8109C168.217 18.6891 168.519 18.5016 168.797 18.2484L170.029 19.5141C169.277 20.3297 168.178 20.7375 166.732 20.7375C165.831 20.7375 165.034 20.5734 164.341 20.2453C163.648 19.9078 163.113 19.4438 162.737 18.8531C162.361 18.2625 162.172 17.5922 162.172 16.8422C162.172 16.1016 162.356 15.4359 162.722 14.8453C163.098 14.2453 163.608 13.7812 164.252 13.4531C164.905 13.1156 165.633 12.9469 166.435 12.9469C167.217 12.9469 167.925 13.1063 168.559 13.425C169.193 13.7438 169.688 14.2031 170.044 14.8031C170.41 15.3938 170.594 16.0828 170.594 16.8703ZM166.45 14.6062C165.925 14.6062 165.484 14.7469 165.128 15.0281C164.772 15.3094 164.554 15.6937 164.475 16.1813H168.41C168.331 15.7031 168.113 15.3234 167.757 15.0422C167.4 14.7516 166.965 14.6062 166.45 14.6062Z"
        fill="#4AA9FF"
      />
      <path
        d="M177.078 12.9469C177.82 12.9469 178.494 13.1109 179.098 13.4391C179.712 13.7578 180.192 14.2125 180.538 14.8031C180.885 15.3844 181.058 16.0641 181.058 16.8422C181.058 17.6203 180.885 18.3047 180.538 18.8953C180.192 19.4766 179.712 19.9313 179.098 20.2594C178.494 20.5781 177.82 20.7375 177.078 20.7375C176.058 20.7375 175.256 20.4328 174.672 19.8234V23.3531H172.355V13.0594H174.568V13.9313C175.142 13.275 175.979 12.9469 177.078 12.9469ZM176.677 18.9375C177.271 18.9375 177.756 18.75 178.132 18.375C178.518 17.9906 178.712 17.4797 178.712 16.8422C178.712 16.2047 178.518 15.6984 178.132 15.3234C177.756 14.9391 177.271 14.7469 176.677 14.7469C176.083 14.7469 175.593 14.9391 175.206 15.3234C174.83 15.6984 174.642 16.2047 174.642 16.8422C174.642 17.4797 174.83 17.9906 175.206 18.375C175.593 18.75 176.083 18.9375 176.677 18.9375Z"
        fill="#4AA9FF"
      />
      <path
        d="M182.682 13.0594H184.984V17.5594L188.875 13.0594H190.999V20.625H188.697V16.125L184.821 20.625H182.682V13.0594Z"
        fill="#4AA9FF"
      />
      <path
        d="M200.326 13.0594V20.625H198.261V18.4594H196.553L195.039 20.625H192.573L194.4 18.15C193.845 17.9438 193.42 17.6484 193.123 17.2641C192.835 16.8703 192.692 16.3969 192.692 15.8438C192.692 14.9531 193.029 14.2687 193.702 13.7906C194.385 13.3031 195.321 13.0594 196.509 13.0594H200.326ZM196.553 14.8453C196.058 14.8453 195.682 14.9297 195.425 15.0984C195.177 15.2672 195.053 15.5391 195.053 15.9141C195.053 16.2891 195.172 16.5656 195.41 16.7438C195.657 16.9125 196.029 16.9969 196.524 16.9969H198.261V14.8453H196.553Z"
        fill="#4AA9FF"
      />
    </g>
    <defs>
      <clipPath id="clip0_5574_9347">
        <rect width="201" height="30" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
