import { SVGProps } from "react"
import { JSX } from "react/jsx-runtime"

export const NewPasswordLogo = (
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="1562.000000pt"
    height="1433.000000pt"
    viewBox="0 0 1562.000000 1433.000000"
    preserveAspectRatio="xMidYMid meet"
    {...props}
  >
    <g
      transform="translate(0.000000,1433.000000) scale(0.100000,-0.100000)"
      fill="#000000"
      stroke="none"
    >
      <path d="M11138 13263 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z" />
      <path d="M11028 13253 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z" />
      <path
        d="M10844 13235 c-80 -11 -271 -49 -374 -75 -119 -29 -419 -133 -560
-192 -159 -67 -450 -220 -431 -226 5 -2 29 9 54 23 25 14 53 25 61 25 9 0 16
3 16 8 0 8 26 22 130 72 236 113 548 228 750 276 231 55 319 73 415 87 55 8
84 15 65 15 -19 0 -76 -6 -126 -13z"
      />
      <path
        d="M11125 13230 c-162 -17 -352 -41 -386 -50 -20 -6 -58 -14 -85 -20
-157 -31 -248 -55 -409 -108 -99 -33 -205 -71 -235 -85 -30 -14 -104 -47 -165
-74 -115 -49 -407 -206 -437 -233 -16 -15 -14 -18 40 -46 31 -16 63 -28 70
-28 6 1 12 -2 12 -6 0 -11 51 -2 67 12 7 6 10 15 6 21 -3 6 -1 7 5 3 15 -9
143 54 135 67 -3 5 0 7 8 5 17 -6 69 18 87 41 8 9 11 11 8 4 -5 -8 1 -9 23 0
17 6 28 14 26 17 -3 4 8 13 23 20 16 8 22 14 14 17 -7 3 -19 -1 -28 -7 -8 -7
-17 -10 -20 -7 -10 10 57 38 76 32 12 -4 20 -2 20 5 0 6 -4 8 -10 5 -5 -3 -10
-4 -10 -1 0 11 110 57 121 50 8 -4 9 -3 5 4 -4 8 4 16 21 23 79 31 345 123
393 135 173 44 374 86 510 104 143 20 477 40 560 34 19 -1 87 -5 150 -9 177
-10 269 -21 413 -51 15 -3 27 -1 27 4 0 5 -46 17 -102 26 -57 9 -114 18 -128
21 -46 8 -314 28 -415 30 -105 2 -377 -9 -480 -19 -48 -4 -53 -4 -25 4 19 5
62 12 95 15 33 3 70 8 82 10 73 16 662 -1 747 -20 12 -3 37 -7 56 -10 57 -7
262 -45 305 -56 22 -6 78 -21 125 -34 47 -13 105 -30 130 -36 25 -7 47 -13 50
-14 3 -1 20 -6 38 -10 17 -4 32 -12 32 -18 0 -6 3 -8 6 -4 4 3 19 0 35 -9 16
-8 31 -14 34 -13 17 3 304 -127 327 -148 9 -8 21 -12 26 -9 5 3 18 -4 30 -14
12 -11 27 -20 35 -20 7 0 18 -5 24 -11 9 -9 29 -16 56 -18 5 -1 5 -5 2 -11 -3
-5 2 -10 12 -10 15 0 15 2 3 14 -19 18 -262 142 -272 138 -5 -1 -8 3 -8 9 0 5
-4 7 -10 4 -5 -3 -10 -2 -10 3 0 12 -78 44 -90 37 -4 -3 -10 -1 -12 4 -5 14
-276 117 -385 147 -54 14 -111 30 -128 35 -158 44 -475 97 -653 109 -169 11
-458 11 -567 0z m-160 -69 c-11 -10 -186 -33 -193 -26 -3 3 1 5 9 6 8 0 50 6
94 14 106 17 101 16 90 6z m-225 -41 c-8 -5 -22 -10 -30 -10 -13 0 -13 1 0 10
8 5 22 10 30 10 13 0 13 -1 0 -10z m-173 -36 c-3 -3 -12 -4 -19 -1 -8 3 -5 6
6 6 11 1 17 -2 13 -5z m-167 -44 c-8 -5 -22 -9 -30 -9 -10 0 -8 3 5 9 27 12
43 12 25 0z m-325 -110 c-3 -5 -11 -10 -16 -10 -6 0 -7 5 -4 10 3 6 11 10 16
10 6 0 7 -4 4 -10z m-159 -69 c-6 -9 -46 -22 -46 -15 0 3 10 9 23 14 29 11 29
11 23 1z m-81 -30 c-16 -15 -166 -94 -170 -89 -4 3 163 97 175 98 2 0 0 -4 -5
-9z m35 -61 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4
-10z m-240 -49 c0 -5 -8 -11 -17 -14 -10 -3 -33 -15 -50 -27 -18 -12 -33 -18
-33 -13 0 4 21 20 48 35 54 32 52 31 52 19z m-129 -75 c-14 -17 -51 -28 -51
-15 0 5 9 9 20 9 11 0 20 5 20 10 0 6 5 10 11 10 8 0 8 -4 0 -14z m86 -41 c0
-8 -7 -15 -15 -15 -12 0 -12 3 -2 15 7 8 13 15 15 15 1 0 2 -7 2 -15z"
      />
      <path
        d="M12180 13101 c8 -6 27 -11 42 -11 15 0 37 -4 50 -9 30 -12 124 -38
168 -47 66 -12 23 6 -81 35 -120 33 -202 48 -179 32z"
      />
      <path
        d="M12493 13019 c10 -6 21 -8 24 -5 4 3 13 0 22 -7 14 -10 14 -10 4 3
-6 9 -24 16 -40 18 -24 1 -26 0 -10 -9z"
      />
      <path
        d="M12561 13006 c11 -11 163 -66 173 -63 8 3 -69 38 -94 43 -3 0 -18 6
-33 12 -28 12 -54 16 -46 8z"
      />
      <path
        d="M12750 12934 c0 -8 72 -36 78 -30 2 3 -1 8 -9 10 -8 3 -26 10 -41 15
-16 6 -28 8 -28 5z"
      />
      <path
        d="M12854 12893 c13 -14 96 -41 96 -32 0 5 -1 9 -2 9 -2 0 -25 7 -52 15
-27 8 -46 12 -42 8z"
      />
      <path
        d="M12960 12850 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2
0 -4 -4 -4 -10z"
      />
      <path
        d="M13004 12825 c11 -8 25 -15 30 -15 6 0 2 7 -8 15 -11 8 -25 15 -30
15 -6 0 -2 -7 8 -15z"
      />
      <path
        d="M13095 12777 c11 -8 54 -31 95 -52 41 -21 89 -49 107 -62 17 -13 35
-23 38 -23 4 0 13 -7 22 -15 8 -9 18 -13 22 -10 3 4 -5 15 -19 25 -14 10 -26
17 -28 15 -2 -2 -14 5 -25 16 -12 10 -65 42 -119 69 -97 49 -131 63 -93 37z"
      />
      <path
        d="M9408 12711 c-25 -16 -49 -35 -54 -42 -4 -8 8 -3 27 9 18 13 44 28
57 34 12 6 22 15 22 20 0 12 -1 11 -52 -21z"
      />
      <path
        d="M13282 12719 c3 -8 15 -19 27 -25 13 -7 28 -17 34 -23 6 -6 17 -11
25 -11 8 0 21 -8 29 -17 11 -14 13 -14 10 -3 -2 8 -12 20 -23 25 -11 6 -33 19
-49 31 -50 35 -58 38 -53 23z"
      />
      <path
        d="M13420 12633 c0 -6 9 -18 20 -28 11 -10 20 -15 20 -10 0 4 7 1 17 -6
13 -11 15 -11 12 -1 -5 16 -69 57 -69 45z"
      />
      <path
        d="M13390 12613 c0 -5 30 -29 67 -54 73 -48 72 -46 153 -113 30 -24 57
-43 59 -40 8 7 -90 91 -139 121 -25 14 -66 42 -92 61 -27 18 -48 30 -48 25z"
      />
      <path
        d="M13510 12565 c7 -8 16 -13 20 -10 4 3 11 -2 14 -11 4 -9 9 -14 12
-11 4 3 19 -6 35 -21 63 -60 78 -72 82 -65 6 10 -122 116 -140 116 -7 0 -13 4
-13 9 0 4 -5 8 -11 8 -8 0 -8 -5 1 -15z"
      />
      <path
        d="M13706 12416 c3 -9 9 -16 13 -16 3 0 19 -11 34 -25 15 -14 27 -23 27
-19 0 4 8 -3 19 -14 10 -12 21 -19 24 -16 6 6 -17 27 -86 79 -25 19 -35 22
-31 11z"
      />
      <path
        d="M13735 12348 c33 -29 99 -90 147 -135 47 -46 90 -83 95 -83 11 0
-181 186 -232 226 -68 52 -73 48 -10 -8z"
      />
      <path
        d="M13830 12316 c0 -2 7 -9 15 -16 9 -7 15 -8 15 -2 0 5 -7 12 -15 16
-8 3 -15 4 -15 2z"
      />
      <path
        d="M13870 12276 c0 -6 61 -66 67 -66 7 0 -16 30 -41 53 -19 17 -26 21
-26 13z"
      />
      <path
        d="M13994 12153 c16 -18 32 -31 34 -28 3 3 3 6 1 8 -2 1 -17 13 -34 27
l-30 25 29 -32z"
      />
      <path
        d="M13990 12115 c0 -5 10 -19 23 -30 12 -11 39 -39 60 -63 20 -23 37
-37 37 -32 0 6 -27 38 -60 72 -33 35 -60 58 -60 53z"
      />
      <path
        d="M14071 12075 c19 -19 35 -35 36 -35 9 0 -42 60 -54 65 -8 3 0 -11 18
-30z"
      />
      <path d="M342 12000 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
      <path d="M332 11945 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z" />
      <path d="M322 11880 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
      <path d="M312 11820 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
      <path d="M301 11764 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
      <path d="M251 11414 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
      <path d="M242 11360 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z" />
      <path
        d="M211 11149 c-12 -96 -24 -192 -27 -214 l-4 -40 9 35 c5 19 11 53 14
75 29 219 38 297 35 307 -2 6 -14 -67 -27 -163z"
      />
      <path d="M162 10760 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z" />
      <path
        d="M157 10713 c-8 -13 -19 -168 -12 -162 3 4 8 38 11 75 3 38 7 75 9 82
5 13 -1 17 -8 5z"
      />
      <path d="M131 10494 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
      <path d="M122 10395 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z" />
      <path
        d="M101 10208 c-31 -322 -47 -567 -37 -557 4 4 10 56 21 199 11 141 16
195 20 230 7 62 17 250 13 250 -3 0 -10 -55 -17 -122z"
      />
      <path
        d="M46 9487 c-4 -71 -5 -131 -3 -133 7 -7 20 217 15 242 -2 10 -8 -39
-12 -109z"
      />
      <path
        d="M30 9164 c0 -54 2 -95 4 -93 3 3 8 43 12 90 4 60 3 89 -5 93 -8 5
-11 -21 -11 -90z"
      />
      <path
        d="M9565 9107 c-16 -12 -31 -21 -32 -19 -12 11 -88 -50 -77 -61 4 -4 -3
-5 -15 -2 -13 2 -21 -1 -21 -11 0 -8 -4 -15 -10 -15 -5 -1 -14 -2 -19 -3 -5 0
-13 -5 -18 -10 -5 -5 -3 -6 5 -2 6 4 12 5 12 2 0 -10 -58 -35 -65 -28 -5 4 -5
2 -2 -5 4 -6 -14 -26 -43 -47 -30 -22 -48 -41 -44 -48 4 -6 3 -8 -4 -5 -5 4
-29 -11 -53 -33 -23 -21 -45 -38 -48 -35 -9 5 -36 -18 -44 -37 -3 -10 -16 -18
-27 -18 -11 0 -20 -7 -20 -15 0 -8 -7 -15 -15 -15 -14 0 -20 -20 -15 -45 1 -5
-1 -7 -5 -3 -4 3 -20 2 -37 -4 -21 -7 -28 -16 -25 -28 2 -9 -1 -17 -7 -17 -26
-1 -41 -10 -72 -42 -19 -19 -37 -32 -40 -28 -4 4 -17 -7 -29 -23 -12 -16 -25
-27 -28 -23 -4 3 -7 1 -7 -6 0 -7 -5 -9 -12 -5 -7 5 -9 1 -6 -13 4 -17 2 -19
-15 -15 -11 3 -18 1 -14 -4 6 -11 -57 -57 -78 -57 -8 0 -12 -4 -9 -8 2 -4 -5
-14 -16 -22 -11 -7 -17 -17 -13 -20 3 -4 -1 -7 -10 -7 -10 0 -27 -11 -39 -25
-12 -14 -28 -25 -35 -25 -8 0 -11 -4 -8 -10 3 -5 1 -10 -6 -10 -20 0 -100 -71
-93 -82 4 -7 2 -8 -5 -4 -6 4 -14 1 -17 -8 -3 -8 -19 -19 -35 -25 -16 -5 -29
-15 -29 -20 0 -6 -10 -11 -22 -11 -19 0 -20 -2 -8 -10 11 -7 11 -10 2 -10 -8
0 -10 -5 -6 -12 5 -8 2 -9 -10 -5 -20 8 -66 -19 -66 -39 0 -8 -6 -14 -14 -14
-7 0 -34 -20 -60 -44 -25 -25 -46 -43 -46 -41 0 2 -12 -7 -26 -20 -15 -14 -31
-22 -37 -18 -5 3 -7 1 -4 -4 4 -6 0 -14 -9 -17 -8 -3 -12 -10 -9 -16 3 -5 0
-7 -9 -4 -8 3 -19 -3 -26 -15 -6 -12 -17 -21 -25 -21 -8 0 -15 -4 -15 -9 0 -5
-8 -12 -17 -16 -10 -3 -31 -17 -48 -31 -16 -15 -24 -23 -17 -20 6 4 12 2 12
-3 0 -6 -11 -11 -24 -11 -14 0 -28 -5 -32 -12 -5 -7 -3 -8 6 -3 9 5 11 4 6 -3
-4 -7 -14 -12 -22 -12 -8 0 -12 -4 -9 -10 3 -5 0 -7 -9 -4 -9 3 -19 -3 -26
-16 -6 -11 -15 -18 -19 -15 -5 3 -12 -2 -15 -11 -4 -9 -9 -14 -12 -11 -3 3
-19 -7 -34 -23 -15 -16 -31 -26 -34 -23 -3 4 -6 -2 -6 -12 0 -10 -3 -16 -6
-13 -3 3 -16 -4 -28 -16 -37 -33 -76 -65 -76 -60 0 4 -33 -21 -61 -48 -9 -9
-20 -14 -23 -10 -3 3 -6 1 -6 -4 0 -5 -10 -20 -21 -34 -11 -14 -17 -18 -13
-10 7 13 6 13 -11 0 -11 -8 -26 -19 -33 -25 -7 -5 -22 -18 -33 -27 -10 -10
-23 -18 -28 -18 -5 0 -11 -4 -13 -8 -1 -5 -33 -31 -70 -59 -37 -28 -64 -54
-61 -57 4 -3 -1 -6 -9 -6 -9 0 -29 -13 -44 -28 -16 -16 -56 -49 -89 -73 -86
-64 -95 -71 -88 -82 3 -6 1 -7 -5 -3 -6 3 -16 0 -24 -6 -31 -30 -116 -98 -123
-98 -3 0 -23 -16 -43 -35 -20 -19 -40 -35 -45 -35 -4 0 -22 -15 -40 -33 -17
-18 -56 -50 -84 -71 -29 -21 -53 -41 -53 -45 0 -3 -15 -14 -33 -24 -17 -10
-50 -35 -72 -57 -22 -21 -43 -39 -47 -40 -5 0 -16 -8 -26 -17 -10 -10 -31 -27
-46 -38 -16 -11 -43 -33 -61 -50 -39 -36 -76 -66 -97 -80 -60 -40 -108 -81
-108 -92 0 -7 -7 -13 -15 -13 -8 0 -15 -4 -15 -10 0 -5 -6 -10 -13 -10 -7 0
-19 -9 -27 -20 -8 -11 -20 -20 -26 -20 -7 0 -26 -16 -43 -35 -17 -19 -34 -35
-39 -35 -4 0 -17 -8 -28 -17 -11 -10 -46 -40 -79 -68 -33 -27 -61 -53 -63 -57
-2 -5 -8 -8 -12 -8 -5 0 -20 -10 -33 -22 -13 -13 -35 -31 -48 -41 -13 -10 -37
-28 -53 -40 -16 -12 -41 -33 -55 -47 -14 -14 -48 -43 -76 -65 -27 -22 -68 -55
-90 -75 -22 -19 -53 -44 -69 -55 -15 -11 -45 -36 -66 -55 -21 -19 -55 -48 -76
-65 -21 -16 -54 -43 -74 -60 -20 -16 -56 -46 -80 -65 -24 -19 -68 -55 -96 -80
-76 -66 -105 -90 -109 -90 -2 0 -17 -12 -32 -26 -24 -23 -107 -91 -253 -209
-21 -16 -91 -75 -156 -130 -223 -189 -335 -275 -356 -275 -16 0 -19 -3 -11
-11 6 -6 44 -13 86 -16 66 -5 80 -3 112 16 35 20 143 104 215 166 20 16 55 46
79 65 25 19 74 60 110 90 37 30 82 66 100 80 18 14 38 30 44 36 7 5 68 54 137
109 69 54 130 103 135 110 6 6 53 44 105 85 52 41 127 101 166 134 39 34 120
100 180 148 203 162 359 288 394 317 129 110 281 231 290 231 5 0 10 4 10 8 0
4 21 24 48 45 26 20 61 48 77 61 17 14 43 36 58 48 16 13 52 43 81 68 29 25
66 55 82 67 74 56 149 118 152 125 2 4 10 8 18 8 8 0 17 6 20 14 3 7 32 32 64
56 32 23 67 52 79 64 22 24 31 31 81 66 19 13 54 44 79 67 24 24 48 43 52 43
5 0 23 15 42 33 18 17 44 39 56 47 13 8 44 34 69 57 26 24 50 43 53 43 3 0 24
17 46 38 63 58 92 82 103 82 6 0 10 4 10 9 0 4 22 24 50 43 27 19 47 38 43 41
-3 4 -2 7 4 7 10 0 29 14 142 108 24 21 51 41 60 46 9 5 23 16 32 25 8 9 33
30 54 46 21 17 52 42 69 57 28 24 167 137 191 156 6 4 37 30 70 57 32 28 68
57 79 65 10 8 33 27 51 43 18 15 37 27 42 27 6 0 19 12 29 26 10 14 37 38 59
54 22 15 49 37 60 47 11 10 36 30 56 43 20 14 56 42 80 63 24 20 71 58 104 84
33 26 64 53 68 60 4 7 12 13 17 13 12 0 42 24 87 68 18 19 33 30 33 25 0 -4 5
0 11 10 6 9 14 15 19 12 4 -3 10 2 13 10 4 8 24 26 47 40 22 14 49 33 59 43
22 21 65 57 116 97 21 17 44 36 52 43 7 7 13 10 13 7 0 -4 15 8 34 27 18 18
66 58 106 88 164 124 278 252 295 328 8 38 8 38 -30 9z"
      />
      <path
        d="M21 8788 c0 -134 2 -238 4 -233 8 24 12 475 4 475 -5 0 -9 -106 -8
-242z"
      />
      <path
        d="M22 8235 c-4 -487 43 -1121 123 -1680 254 -1773 883 -3239 1983
-4625 363 -456 784 -919 1412 -1551 403 -406 357 -378 604 -360 89 7 178 17
199 22 69 18 810 376 1197 579 729 381 1249 701 1770 1089 85 64 161 121 168
126 11 9 8 20 -17 61 -17 27 -39 65 -50 84 -46 80 -35 82 -178 -32 -70 -57
-143 -114 -162 -128 -18 -14 -47 -37 -65 -51 -17 -14 -62 -47 -101 -74 -38
-26 -72 -51 -75 -54 -3 -3 -18 -15 -35 -27 -16 -11 -68 -47 -114 -79 -155
-107 -425 -286 -516 -342 -233 -143 -452 -273 -580 -343 -302 -165 -493 -265
-708 -372 -219 -109 -407 -198 -417 -198 -6 0 -10 -4 -10 -10 0 -5 -5 -10 -12
-10 -11 0 -178 -76 -331 -151 -69 -34 -138 -45 -192 -30 -47 12 -87 51 -475
446 -351 358 -488 501 -650 680 -74 82 -160 176 -191 209 -228 249 -568 671
-779 968 -36 50 -79 110 -96 133 -42 56 -174 256 -174 263 0 3 -7 13 -16 23
-15 17 -105 160 -169 269 -101 173 -135 231 -167 290 -62 113 -286 562 -316
635 -16 39 -48 113 -70 165 -22 52 -62 149 -87 215 -26 66 -50 129 -55 140 -5
11 -25 67 -44 125 -20 58 -52 152 -72 210 -36 103 -44 130 -105 335 -37 127
-121 459 -149 590 -59 279 -103 517 -126 670 -14 94 -27 181 -29 195 -7 36
-14 106 -20 175 -8 106 -16 179 -26 215 -5 19 -9 62 -9 96 0 33 -4 85 -9 115
-5 30 -12 101 -15 159 -3 58 -10 181 -16 273 -7 93 -15 309 -19 480 l-8 312
-1 -230z"
      />
      <path d="M11385 8350 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z" />
      <path
        d="M11376 8283 c4 -20 3 -24 -4 -14 -8 10 -11 6 -17 -17 -3 -18 -4 -36
0 -42 3 -5 1 -10 -6 -10 -6 0 -9 -8 -6 -20 3 -11 1 -18 -3 -15 -5 3 -11 -9
-15 -27 -12 -60 -149 -529 -215 -733 -23 -71 -52 -164 -65 -205 -13 -41 -44
-133 -70 -205 -26 -71 -51 -141 -55 -155 -5 -14 -25 -68 -45 -120 -20 -52 -39
-105 -42 -117 -3 -13 -8 -23 -12 -23 -4 0 -7 -9 -8 -20 -1 -11 -9 -34 -17 -51
-17 -34 -27 -27 104 -75 41 -15 128 -51 193 -80 64 -28 117 -49 117 -45 0 3 4
0 9 -7 4 -7 15 -14 23 -16 9 -1 22 -6 29 -12 8 -6 21 -10 29 -9 8 0 17 -2 20
-6 10 -14 43 -13 47 1 4 13 3 13 -7 0 -10 -13 -11 -13 -7 1 3 9 1 22 -4 28 -6
8 -5 8 3 1 19 -16 32 -12 18 5 -11 13 -10 14 4 9 21 -8 18 18 -4 28 -12 5 -12
7 3 7 9 1 17 5 17 11 0 5 -8 7 -17 4 -15 -5 -15 -4 1 6 15 10 16 14 5 21 -11
7 -9 9 6 9 14 0 16 3 8 11 -6 6 -9 18 -6 27 4 10 2 13 -5 8 -21 -13 -21 12 0
30 14 12 18 14 13 4 -8 -13 -7 -13 9 0 18 14 18 15 0 20 -19 5 -24 26 -12 46
8 13 -1 75 -13 90 -5 6 -3 18 6 30 8 10 14 24 15 29 0 6 -5 3 -10 -5 -9 -13
-10 -13 -10 0 0 9 6 22 12 28 10 10 10 15 -2 22 -12 8 -12 10 -1 10 11 0 11 3
1 15 -10 12 -10 15 4 15 9 0 16 5 16 11 0 5 -4 7 -10 4 -6 -4 -10 5 -10 19 0
14 4 26 8 26 4 0 8 9 9 20 1 13 -2 18 -10 13 -7 -3 -4 2 5 13 16 18 17 51 3
92 -2 6 1 12 7 12 6 0 8 3 5 7 -4 3 -1 13 6 21 10 13 9 15 -6 9 -10 -4 -16 -3
-12 2 3 5 0 13 -5 16 -7 5 -5 12 7 22 13 10 15 16 6 19 -16 7 -17 36 -1 52 14
14 -8 45 -26 35 -7 -4 -6 -1 1 9 7 8 10 17 7 21 -3 3 4 15 16 27 17 17 19 24
10 30 -9 6 -9 9 1 13 9 3 7 6 -5 6 -20 1 -20 1 0 16 16 13 16 14 2 9 -16 -5
-18 0 -18 40 0 25 4 46 9 46 11 0 8 46 -2 57 -13 12 -7 42 11 52 9 6 12 11 5
11 -8 0 -9 10 -2 36 8 26 7 39 -2 50 -10 12 -9 13 4 8 13 -4 15 0 11 20 -2 14
-1 26 4 26 10 0 9 41 -1 58 -5 8 -3 19 4 28 10 12 10 17 0 23 -6 4 -11 12 -9
17 1 5 -3 19 -10 32 -11 19 -10 22 5 22 15 0 16 2 3 10 -12 8 -12 10 5 10 18
1 19 1 2 11 -11 7 -16 18 -13 35 3 13 1 24 -4 24 -8 0 -8 9 2 50 1 8 -2 14 -8
13 -6 -1 -11 4 -11 12 0 7 7 11 15 7 11 -4 13 -2 7 9 -5 7 -9 25 -9 39 -1 15
-6 24 -14 22 -8 -1 -11 2 -7 8 3 5 2 10 -4 10 -13 0 -5 42 11 52 10 6 10 8 -1
8 -10 0 -12 12 -8 50 3 28 2 50 -3 50 -4 0 -6 9 -3 20 3 11 9 17 14 14 16 -10
9 1 -11 19 -20 17 -20 16 -14 -10z m-3 -1718 c-3 -9 -8 -14 -10 -11 -3 3 -2 9
2 15 9 16 15 13 8 -4z"
      />
      <path
        d="M10846 7315 c-3 -9 -6 -22 -5 -28 0 -7 5 -1 10 12 5 13 8 26 5 28 -2
2 -6 -3 -10 -12z"
      />
      <path
        d="M10828 7263 c-3 -4 -20 -55 -39 -113 -18 -58 -37 -106 -41 -108 -4
-2 -8 -10 -8 -18 0 -23 -127 -357 -166 -435 -22 -45 -22 -47 -4 -52 24 -6 37
25 121 278 34 105 75 226 90 270 52 154 59 178 55 182 -2 2 -6 1 -8 -4z"
      />
      <path
        d="M14440 6795 c-7 -8 -17 -15 -22 -15 -5 0 -69 -60 -141 -133 -73 -73
-163 -160 -202 -192 -63 -54 -106 -92 -185 -164 -77 -70 -464 -342 -565 -397
-11 -6 -56 -32 -100 -58 -81 -49 -357 -186 -374 -186 -5 0 -14 -7 -21 -15 -7
-8 -18 -15 -26 -15 -7 0 -41 -13 -76 -29 -35 -16 -94 -41 -133 -55 -203 -74
-245 -90 -245 -96 0 -4 23 -37 50 -73 42 -53 55 -64 73 -59 12 3 117 40 232
83 256 94 545 235 784 383 212 131 492 341 624 470 96 93 120 123 74 93 -23
-15 -24 -15 -22 17 1 19 5 39 9 45 4 6 9 23 12 37 3 19 1 24 -8 18 -10 -5 -10
-4 0 7 7 8 17 15 23 15 5 1 13 2 18 3 5 0 9 7 8 14 -1 32 4 47 16 47 7 0 20
12 30 28 9 15 30 42 46 61 17 18 42 47 55 65 14 17 39 48 55 70 17 21 31 40
31 42 0 8 -8 3 -20 -11z"
      />
      <path d="M11405 6299 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z" />
      <path
        d="M11381 6257 c2 -1 13 -9 24 -17 19 -14 19 -14 6 3 -7 9 -18 17 -24
17 -6 0 -8 -1 -6 -3z"
      />
      <path
        d="M9670 5148 c-47 -4 -130 -19 -185 -33 -97 -25 -255 -81 -265 -94 -3
-3 -39 -24 -80 -45 -41 -22 -109 -64 -150 -94 -138 -102 -326 -308 -380 -418
-19 -39 -50 -78 -67 -85 -13 -6 -70 -136 -103 -234 -29 -86 -74 -274 -67 -281
2 -2 18 47 36 108 17 62 51 156 74 208 39 86 45 95 69 95 15 -1 24 3 21 8 -3
5 4 4 16 -2 12 -7 26 -9 31 -6 5 3 26 -1 48 -8 22 -8 52 -13 66 -11 14 1 26
-1 26 -5 0 -3 11 -8 25 -9 14 -1 25 2 25 7 0 5 9 7 19 4 13 -3 23 2 31 17 6
12 10 24 7 26 -4 5 -165 48 -239 65 -27 6 -48 14 -48 18 0 16 60 91 87 108 34
23 123 25 115 4 -3 -9 -1 -12 6 -7 7 4 12 2 12 -3 0 -12 43 -15 54 -4 11 11
46 11 39 0 -4 -6 0 -8 10 -4 12 4 15 1 11 -11 -5 -16 -4 -16 7 -1 11 15 14 15
29 -4 13 -16 19 -17 25 -7 5 8 4 11 -2 7 -11 -7 -28 22 -18 31 3 3 5 0 5 -7 0
-22 41 9 121 88 97 98 146 141 153 133 3 -3 6 0 6 6 0 15 27 16 32 1 3 -9 10
-9 28 0 21 10 22 10 9 -1 -14 -11 -14 -15 -3 -26 12 -10 14 -10 15 5 0 10 3
13 5 8 2 -6 -4 -29 -15 -50 -14 -29 -16 -42 -8 -47 9 -6 8 -8 -1 -8 -7 0 -11
-4 -7 -9 3 -5 1 -13 -5 -16 -8 -5 -8 -11 0 -20 8 -9 8 -14 -1 -17 -6 -2 -11
-16 -11 -31 0 -18 -6 -27 -16 -27 -12 0 -9 -5 8 -17 l24 -17 -23 -6 c-22 -5
-23 -7 -8 -22 15 -14 15 -17 1 -29 -12 -10 -12 -14 -2 -20 10 -7 10 -9 -2 -9
-12 0 -16 -11 -16 -42 0 -24 4 -46 10 -50 6 -4 -1 -8 -16 -8 -16 0 -28 -5 -28
-11 0 -5 5 -7 10 -4 15 9 12 -11 -3 -24 -8 -6 -20 -30 -27 -53 -8 -24 -15 -45
-16 -48 -1 -3 -5 -15 -9 -27 -4 -13 -11 -23 -16 -23 -5 0 -9 -5 -9 -11 0 -9
-54 -93 -70 -109 -3 -3 -9 -12 -15 -21 -35 -57 -88 -129 -96 -129 -5 0 -9 -6
-9 -13 0 -13 -118 -180 -131 -185 -5 -2 -18 -20 -31 -40 -12 -20 -28 -41 -35
-45 -7 -4 -13 -14 -13 -21 0 -7 -10 -21 -21 -32 -19 -17 -21 -26 -15 -94 6
-75 23 -157 52 -258 l16 -52 111 131 c159 187 312 384 475 610 73 100 261 376
293 430 22 36 96 155 149 239 61 98 100 171 95 179 -3 6 3 15 14 20 26 14 36
48 20 69 -11 14 -11 15 1 5 8 -7 17 -13 22 -13 10 0 -2 33 -16 43 -6 4 2 5 18
1 25 -6 28 -5 21 15 -5 15 -2 21 9 21 9 0 16 5 16 10 0 6 -5 10 -11 10 -8 0
-8 4 1 15 6 8 10 22 7 32 -4 15 -5 14 -6 -4 -1 -16 -6 -20 -20 -16 -19 5 -19
5 -1 39 19 34 19 34 -2 54 -13 12 -17 20 -9 20 7 0 3 9 -10 20 -26 21 -20 26
11 10 16 -9 18 -7 15 10 -3 18 2 20 43 22 26 1 58 2 72 3 14 1 30 4 36 8 12 7
187 -8 264 -24 114 -23 321 -161 473 -317 48 -49 87 -87 87 -85 0 17 -137 155
-218 220 -315 254 -660 358 -1062 321z m-380 -408 c0 -5 -8 -10 -17 -10 -15 0
-16 2 -3 10 19 12 20 12 20 0z"
      />
      <path
        d="M12710 4089 c0 -24 23 -21 28 4 2 10 -3 17 -12 17 -10 0 -16 -9 -16
-21z"
      />
      <path
        d="M12635 4093 c-117 -15 -1123 -185 -1135 -192 -29 -16 -10 -26 67 -37
65 -9 95 -8 183 7 58 9 123 20 145 23 80 10 120 17 133 21 7 3 74 14 149 25
75 11 153 24 172 29 20 5 89 17 154 26 l117 18 1 36 c0 20 3 30 6 23 4 -9 8
-10 18 -2 10 8 15 0 23 -37 20 -86 25 -136 32 -308 l7 -170 1 145 c1 80 -2
183 -8 230 -5 47 -10 104 -12 128 -1 23 -7 41 -12 41 -6 -1 -24 -4 -41 -6z"
      />
      <path d="M7021 4074 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
      <path d="M7012 4010 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
      <path d="M7002 3945 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z" />
      <path
        d="M6985 3785 c-4 -59 -5 -109 -2 -111 3 -3 8 42 12 101 4 58 5 108 2
111 -2 3 -8 -43 -12 -101z"
      />
      <path
        d="M11358 3883 c-20 -3 -28 -10 -28 -24 0 -27 40 -26 47 2 5 16 11 19
37 14 23 -5 27 -4 16 4 -15 11 -23 11 -72 4z"
      />
      <path
        d="M11272 3853 c5 -25 28 -28 28 -4 0 12 -6 21 -16 21 -9 0 -14 -7 -12
-17z"
      />
      <path d="M8362 3790 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
      <path d="M6982 3580 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
      <path
        d="M6982 3464 c-2 -107 11 -252 38 -414 23 -138 60 -303 67 -296 3 2 -7
55 -21 118 -43 192 -72 417 -79 608 -3 74 -3 74 -5 -16z"
      />
      <path d="M12703 3475 c0 -44 2 -61 4 -37 2 23 2 59 0 80 -2 20 -4 1 -4 -43z" />
      <path d="M12692 3320 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
      <path
        d="M11328 3277 c8 -9 6 -16 -7 -24 -10 -6 -21 -26 -25 -45 -3 -18 -18
-64 -32 -103 -92 -249 -205 -435 -363 -597 -120 -123 -183 -171 -431 -326
l-25 -16 24 -6 c21 -5 16 -9 -40 -37 -166 -80 -378 -126 -599 -127 -137 -1
-270 16 -270 34 0 4 91 10 203 13 218 6 292 17 447 68 383 125 717 411 900
768 35 69 14 45 -28 -32 -96 -174 -268 -365 -438 -485 -211 -150 -513 -259
-731 -263 -118 -2 -282 49 -470 145 l-108 56 -142 5 c-104 4 -143 2 -143 -6 0
-6 9 -9 20 -6 10 3 18 1 17 -4 -1 -5 23 -23 53 -39 205 -109 397 -162 640
-177 116 -7 112 -21 -7 -22 -199 -2 -449 65 -650 175 -46 25 -86 45 -90 46 -7
1 -33 -37 -50 -72 -4 -8 -38 -69 -77 -135 -38 -66 -108 -190 -156 -275 -48
-85 -119 -211 -159 -279 -39 -69 -71 -126 -71 -128 0 -2 -28 -51 -62 -111 -33
-59 -72 -126 -85 -150 -15 -25 -20 -42 -13 -42 7 0 24 -9 39 -19 15 -11 39
-23 54 -26 15 -4 27 -13 27 -21 0 -8 4 -13 8 -11 9 2 63 -36 93 -67 10 -11 19
-17 19 -13 0 4 10 2 23 -5 12 -7 24 -13 27 -13 3 0 28 -11 55 -25 61 -30 145
-59 145 -50 0 3 -5 11 -12 18 -17 17 -11 16 90 -18 49 -17 103 -32 120 -33 17
-1 28 -4 26 -6 -8 -9 135 -40 158 -34 18 4 20 3 8 -6 -12 -9 -9 -11 15 -12 17
-1 38 -1 48 -1 10 1 16 -2 13 -6 -3 -5 8 -10 24 -11 68 -5 80 -6 80 -11 0 -3
19 -7 43 -8 105 -5 155 -10 188 -19 20 -6 42 -7 49 -3 21 13 -4 20 -91 24 -46
2 -109 9 -139 16 -30 6 -68 14 -85 17 -16 2 -68 13 -114 23 -46 10 -90 16 -99
12 -10 -3 -13 -1 -8 6 4 7 1 12 -9 12 -9 0 -52 12 -95 26 -44 14 -85 22 -91
18 -8 -5 -9 -3 -4 6 6 10 4 12 -8 7 -10 -3 -17 -2 -17 3 0 6 -6 10 -12 10 -7
0 -24 6 -38 13 -14 8 -47 20 -73 27 -26 8 -47 18 -47 23 0 5 -9 7 -19 4 -12
-3 -27 3 -37 14 -9 11 -22 18 -28 17 -10 -3 -66 29 -212 121 -55 34 -68 55
-52 81 14 21 34 28 22 8 -4 -7 14 9 39 36 26 26 45 52 42 56 -3 4 6 11 20 14
14 4 25 11 25 17 0 6 4 8 8 5 5 -3 7 -19 6 -35 -1 -17 2 -28 7 -25 5 3 9 -2 9
-11 0 -9 5 -13 10 -10 6 3 10 1 10 -6 0 -7 3 -9 7 -6 3 4 12 -2 20 -13 8 -11
20 -20 26 -20 8 0 7 3 -2 9 -10 6 -12 21 -8 50 4 31 2 41 -9 41 -8 0 -14 6
-14 14 0 8 -7 17 -15 20 -14 5 -14 4 0 -16 21 -30 19 -36 -9 -34 -19 1 -22 4
-15 18 7 12 3 21 -13 32 -19 13 -20 16 -5 16 14 0 16 5 11 26 -5 19 -4 25 4
20 7 -4 20 -2 29 3 17 10 17 10 1 11 -22 0 -23 7 -2 27 9 9 19 12 22 7 10 -14
42 -16 42 -3 0 6 7 9 15 5 19 -7 15 14 -5 32 -13 11 -12 11 7 3 27 -14 36 -14
28 -1 -3 6 -2 10 4 10 16 0 19 -24 4 -41 -12 -14 -12 -19 1 -32 14 -13 13 -15
-7 -21 -31 -8 -37 -7 -37 5 0 5 5 7 12 3 7 -4 8 -3 4 4 -4 7 -14 12 -21 12
-11 0 -12 -6 -5 -25 7 -20 6 -25 -5 -25 -12 0 -12 -5 -3 -25 6 -13 16 -22 21
-18 6 4 8 0 4 -11 -6 -14 -3 -15 13 -6 16 9 18 15 10 30 -13 24 -13 40 1 40 6
0 8 -7 5 -16 -5 -13 -4 -14 7 -6 8 7 17 9 20 5 4 -3 7 -1 7 5 0 7 -6 12 -12
13 -7 0 -3 4 10 9 12 5 22 16 22 25 0 8 5 15 11 15 5 0 7 5 4 10 -3 6 -1 10 4
10 6 0 11 12 11 26 0 14 4 22 10 19 5 -3 16 10 24 30 8 19 19 35 23 35 5 0 17
20 27 45 11 25 25 50 32 56 8 6 25 36 40 68 15 31 30 58 34 61 4 3 15 21 25
40 10 19 21 37 24 40 12 8 47 73 61 110 7 19 20 43 28 53 8 9 20 27 26 39 6
13 21 28 34 34 12 6 19 14 14 19 -4 4 1 5 12 1 10 -3 38 -11 63 -18 32 -8 43
-15 38 -26 -4 -11 0 -13 15 -9 11 3 18 2 15 -3 -3 -4 13 -12 35 -18 29 -7 38
-7 33 2 -4 8 -2 8 7 -1 7 -6 19 -9 26 -6 8 3 14 0 14 -7 0 -8 10 -10 33 -5 21
4 27 3 19 -3 -16 -11 85 -27 228 -38 111 -8 545 23 625 46 89 25 262 84 274
93 7 6 19 12 25 13 7 1 30 13 52 26 21 14 42 25 45 24 11 -2 49 36 44 44 -5 8
32 23 48 21 5 -1 6 3 2 9 -3 5 1 10 9 10 23 0 19 21 -5 27 -20 6 -20 6 2 29
12 13 27 24 32 24 6 0 0 -9 -13 -20 -13 -11 -21 -20 -19 -21 2 0 12 -2 22 -4
11 -2 16 1 14 8 -2 7 5 24 15 40 11 15 15 27 10 27 -6 0 -8 9 -5 20 3 11 0 20
-6 20 -5 0 -9 -6 -7 -12 1 -7 1 -10 -1 -5 -9 14 -22 7 -20 -12 0 -11 6 -17 14
-14 7 3 13 1 13 -5 0 -11 -18 -11 -37 1 -8 5 -21 -2 -35 -18 -12 -14 -26 -25
-32 -25 -6 0 -3 7 6 14 10 7 15 15 12 19 -3 3 3 8 13 12 25 9 165 168 240 273
127 178 240 419 230 491 -3 20 -3 28 -1 18 3 -9 10 -15 15 -12 12 8 12 45 -1
45 -5 0 -10 5 -10 11 0 5 3 8 8 6 4 -3 7 14 6 36 -2 61 -3 67 -15 67 -8 0 -8
-4 -1 -13z m-533 -998 c11 -16 1 -19 -13 -3 -7 8 -8 14 -3 14 5 0 13 -5 16
-11z m-1585 -169 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0
11 -4 11 -10z m-500 -634 c0 -2 -9 -6 -20 -9 -11 -3 -20 -1 -20 4 0 5 9 9 20
9 11 0 20 -2 20 -4z"
      />
      <path d="M12681 3214 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
      <path
        d="M11142 2965 c-17 -37 -14 -45 4 -12 9 16 14 31 11 33 -2 2 -9 -7 -15
-21z"
      />
      <path
        d="M7091 2734 c-1 -16 40 -147 44 -142 2 2 -7 37 -20 78 -13 41 -24 70
-24 64z"
      />
      <path d="M7141 2570 c0 -8 4 -22 9 -30 12 -18 12 -2 0 25 -6 13 -9 15 -9 5z" />
      <path
        d="M7176 2470 c3 -14 11 -32 16 -40 6 -10 7 -7 2 10 -3 14 -11 32 -16
40 -6 10 -7 7 -2 -10z"
      />
      <path
        d="M12492 2328 c-7 -7 -12 -17 -12 -24 0 -7 -11 -34 -24 -61 -13 -26
-25 -53 -27 -60 -2 -7 -14 -25 -27 -40 -27 -33 -183 -272 -212 -323 -10 -19
-35 -52 -54 -72 -20 -20 -36 -40 -36 -43 0 -7 -99 -130 -146 -181 -16 -17 -34
-29 -40 -27 -6 2 -21 -12 -33 -32 -17 -29 -25 -35 -42 -30 -17 6 -20 3 -17
-15 2 -16 -4 -25 -19 -31 -29 -11 -41 8 -17 26 11 7 16 17 11 21 -4 4 -22 -6
-40 -22 l-32 -29 31 35 c17 19 29 41 27 48 -3 6 0 12 6 12 12 0 112 96 105
102 -2 3 25 31 61 63 38 34 60 62 54 65 -6 4 4 21 26 41 20 19 50 59 68 89 17
30 48 77 69 103 21 27 38 55 38 62 0 8 6 18 14 22 13 8 36 45 36 59 0 3 -7 1
-15 -6 -8 -7 -15 -16 -15 -20 0 -22 -185 -277 -294 -405 -106 -125 -296 -301
-456 -422 -95 -72 -309 -201 -438 -265 -78 -38 -120 -64 -114 -70 6 -6 21 -4
40 6 38 20 66 21 32 1 -16 -9 -19 -15 -10 -15 42 -3 -326 -157 -430 -181 -19
-4 -69 -17 -110 -28 -41 -11 -93 -22 -115 -25 -22 -2 -47 -7 -55 -10 -55 -20
-560 -61 -560 -46 0 3 -24 6 -52 6 -229 2 -558 72 -830 178 -189 73 -307 132
-317 158 -5 13 -22 30 -37 38 -16 8 -55 32 -86 53 -32 20 -61 37 -65 36 -31
-5 -99 38 -226 144 -21 17 -51 39 -65 47 -15 9 -45 34 -68 56 -23 21 -56 53
-75 70 -44 41 -198 202 -224 234 -50 63 -75 91 -75 85 0 -25 229 -281 350
-391 421 -384 872 -607 1440 -710 126 -23 180 -27 414 -31 399 -7 721 21 950
83 50 13 109 29 131 34 67 15 219 71 236 86 8 8 26 14 38 14 12 0 20 4 17 9
-3 5 15 14 39 21 25 7 48 17 51 21 3 5 12 9 20 9 9 0 22 7 30 15 14 13 14 15
-1 15 -12 0 -9 8 13 30 17 17 35 30 41 30 6 0 10 3 10 8 -3 20 3 30 25 40 13
6 40 25 60 42 20 18 36 29 36 26 0 -4 11 5 25 20 14 15 25 22 25 15 0 -6 24
12 53 41 28 29 77 73 107 98 81 66 280 271 375 385 139 169 329 471 392 624
13 29 20 57 17 62 -3 5 -1 9 4 9 6 0 12 7 16 15 7 18 -5 20 -22 3z m-734 -966
c-10 -2 -18 -10 -18 -18 0 -8 -4 -14 -10 -14 -5 0 -10 -7 -10 -15 0 -8 -4 -15
-10 -15 -5 0 -10 5 -10 11 0 5 -6 7 -12 3 -7 -4 2 9 21 29 22 24 39 33 50 29
14 -6 14 -7 -1 -10z m-59 1 c0 -5 -28 -30 -62 -57 l-62 -49 55 56 c51 53 71
67 69 50z m-17 -85 c-7 -7 -12 -8 -12 -2 0 14 12 26 19 19 2 -3 -1 -11 -7 -17z
m-62 -26 c0 -4 -15 -16 -32 -26 l-33 -19 25 26 c25 26 40 33 40 19z m-65 -62
c-3 -5 -16 -10 -28 -9 -21 0 -21 1 -2 9 28 12 37 12 30 0z m-80 -10 c-3 -5
-12 -10 -18 -10 -7 0 -6 4 3 10 19 12 23 12 15 0z m-96 -64 c-2 -2 -48 -31
-102 -64 -54 -33 -98 -64 -98 -69 1 -8 -2 -8 -31 -4 -11 1 97 71 111 71 3 0
26 16 51 35 25 18 51 34 59 34 8 1 12 -1 10 -3z m1 -46 c0 -5 -2 -10 -4 -10
-3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m-287 -122 c3 -9 -6 -13
-24 -14 -45 -2 -35 16 12 25 4 0 9 -5 12 -11z m-826 -351 c3 -4 -5 -7 -20 -7
-14 0 -28 4 -32 9 -6 11 41 8 52 -2z"
      />
      <path
        d="M10930 2306 c0 -2 7 -6 15 -10 8 -3 15 -1 15 4 0 6 -7 10 -15 10 -8
0 -15 -2 -15 -4z"
      />
      <path d="M12276 2133 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z" />
      <path
        d="M7555 1763 c15 -24 30 -43 32 -43 7 0 -31 61 -46 73 -8 7 -2 -7 14
-30z"
      />
      <path
        d="M11965 1620 c-16 -16 -24 -30 -18 -30 10 0 34 24 47 48 12 20 2 14
-29 -18z"
      />
      <path
        d="M8616 1341 c-4 -5 4 -15 16 -22 22 -12 22 -11 8 7 -8 10 -15 20 -16
22 -1 2 -5 -1 -8 -7z"
      />
      <path d="M8546 1243 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z" />
      <path
        d="M8675 1220 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"
      />
      <path
        d="M8580 1190 c0 -5 7 -10 16 -10 8 0 12 5 9 10 -3 6 -10 10 -16 10 -5
0 -9 -4 -9 -10z"
      />
      <path
        d="M11545 1050 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"
      />
      <path
        d="M11526 998 c-21 -10 -36 -21 -33 -24 6 -7 63 22 72 36 7 12 9 13 -39
-12z"
      />
      <path
        d="M11410 970 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"
      />
      <path d="M11453 956 c0 -8 6 -12 14 -9 7 3 10 9 7 14 -8 14 -21 11 -21 -5z" />
      <path
        d="M11391 945 c0 -5 3 -16 8 -24 8 -12 12 -13 26 -1 11 9 13 16 5 24 -8
8 -13 7 -19 -3 -7 -11 -9 -11 -14 0 -3 8 -6 10 -6 4z"
      />
      <path
        d="M11365 900 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"
      />
      <path
        d="M10750 848 c-80 -29 -147 -53 -149 -55 -24 -19 87 6 164 36 103 41
165 72 140 70 -6 0 -75 -23 -155 -51z"
      />
      <path
        d="M10508 771 c-62 -18 -79 -27 -45 -26 35 1 127 28 127 37 0 11 -14 9
-82 -11z"
      />
      <path d="M10271 721 c-10 -6 1 -8 30 -4 24 3 46 7 48 9 10 9 -65 3 -78 -5z" />
      <path d="M9965 689 c-20 -5 -12 -6 24 -4 30 3 56 7 58 9 7 7 -47 3 -82 -5z" />
      <path d="M9766 682 c-6 -10 30 -9 40 1 4 4 -2 7 -14 7 -11 0 -23 -4 -26 -8z" />
    </g>
  </svg>
)
