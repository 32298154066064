import { SVGProps } from "react"
import { JSX } from "react/jsx-runtime"

export const LogoRegisterImage = (
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) => (
  <svg
    width="195"
    height="30"
    viewBox="0 0 195 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clip-path="url(#clip0_5501_265343)">
      <path
        d="M0.625 5.625C0.625 2.87783 2.91118 0.625 5.76355 0.625H24.9754C27.8277 0.625 30.1139 2.87783 30.1139 5.625V24.375C30.1139 27.1222 27.8277 29.375 24.9754 29.375H5.76355C2.91118 29.375 0.625 27.1222 0.625 24.375V5.625Z"
        fill="#4AA9FF"
        stroke="#4AA9FF"
        stroke-width="1.25"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24.9754 1.17188H5.76355C3.24358 1.17188 1.20074 3.16561 1.20074 5.625V24.375C1.20074 26.8344 3.24358 28.8281 5.76355 28.8281H24.9754C27.4953 28.8281 29.5382 26.8344 29.5382 24.375V5.625C29.5382 3.16561 27.4953 1.17188 24.9754 1.17188ZM5.76355 0C2.58043 0 0 2.5184 0 5.625V24.375C0 27.4816 2.58043 30 5.76355 30H24.9754C28.1585 30 30.7389 27.4816 30.7389 24.375V5.625C30.7389 2.5184 28.1585 0 24.9754 0H5.76355Z"
        fill="#4AA9FF"
      />
      <path
        d="M7.68457 7.49805H10.7585V10.498H13.8324V13.498H16.9062V16.498L13.8324 16.498V19.498L10.7585 19.498V22.498H7.68457V16.498L10.7585 16.498V13.498H7.68457V7.49805Z"
        fill="white"
      />
      <path
        d="M16.9062 19.498V16.498L19.9801 16.498V19.498L16.9062 19.498Z"
        fill="white"
      />
      <path d="M19.9801 19.498H23.054V22.498H19.9801V19.498Z" fill="white" />
      <path
        d="M16.9062 19.498L13.8324 19.498V22.498H16.9062L16.9062 19.498Z"
        fill="white"
      />
      <path
        d="M19.9801 16.498L19.9801 13.498H16.9062L16.9062 10.498H13.8324V7.49805H19.9801V10.498H23.054V16.498H19.9801Z"
        fill="white"
      />
      <path
        d="M47.8952 20.625L47.8808 14.7328L44.9126 19.5844H43.8752L40.9069 14.8453V20.625H38.7168V10.7812H40.6476L44.4227 16.8984L48.1402 10.7812H50.071L50.0854 20.625H47.8952Z"
        fill="#4AA9FF"
      />
      <path
        d="M56.0491 20.7375C55.2326 20.7375 54.4977 20.5734 53.8445 20.2453C53.2009 19.9078 52.6966 19.4438 52.3316 18.8531C51.9666 18.2625 51.7841 17.5922 51.7841 16.8422C51.7841 16.0922 51.9666 15.4219 52.3316 14.8313C52.6966 14.2406 53.2009 13.7812 53.8445 13.4531C54.4977 13.1156 55.2326 12.9469 56.0491 12.9469C56.8656 12.9469 57.5956 13.1156 58.2392 13.4531C58.8828 13.7812 59.3871 14.2406 59.7522 14.8313C60.1172 15.4219 60.2997 16.0922 60.2997 16.8422C60.2997 17.5922 60.1172 18.2625 59.7522 18.8531C59.3871 19.4438 58.8828 19.9078 58.2392 20.2453C57.5956 20.5734 56.8656 20.7375 56.0491 20.7375ZM56.0491 18.9375C56.6254 18.9375 57.0961 18.75 57.4612 18.375C57.8358 17.9906 58.0231 17.4797 58.0231 16.8422C58.0231 16.2047 57.8358 15.6984 57.4612 15.3234C57.0961 14.9391 56.6254 14.7469 56.0491 14.7469C55.4727 14.7469 54.9972 14.9391 54.6226 15.3234C54.248 15.6984 54.0607 16.2047 54.0607 16.8422C54.0607 17.4797 54.248 17.9906 54.6226 18.375C54.9972 18.75 55.4727 18.9375 56.0491 18.9375Z"
        fill="#4AA9FF"
      />
      <path
        d="M70.4217 18.825V22.2563H68.3324V20.625H62.8859V22.2563H60.7966V18.825H61.1136C61.5939 18.8156 61.9253 18.5391 62.1078 17.9953C62.2903 17.4516 62.4056 16.6781 62.4536 15.675L62.5545 13.0594H69.3122V18.825H70.4217ZM64.4853 15.8297C64.4564 16.5984 64.394 17.2266 64.2979 17.7141C64.2115 18.2016 64.0434 18.5719 63.7936 18.825H67.0644V14.8594H64.5285L64.4853 15.8297Z"
        fill="#4AA9FF"
      />
      <path
        d="M79.6026 13.0594L76.1013 21.0891C75.7459 21.9609 75.304 22.575 74.7757 22.9313C74.2569 23.2875 73.6278 23.4656 72.8881 23.4656C72.4847 23.4656 72.086 23.4047 71.6922 23.2828C71.2983 23.1609 70.9765 22.9922 70.7268 22.7766L71.5481 21.2156C71.721 21.3656 71.9179 21.4828 72.1388 21.5672C72.3694 21.6516 72.5951 21.6938 72.8161 21.6938C73.1234 21.6938 73.3732 21.6188 73.5653 21.4688C73.7574 21.3281 73.9303 21.0891 74.084 20.7516L74.1129 20.6813L70.7556 13.0594H73.0754L75.2512 18.1922L77.4413 13.0594H79.6026Z"
        fill="#4AA9FF"
      />
      <path
        d="M87.5732 13.0594V20.625H85.3254V14.8594H82.8759L82.8039 16.1953C82.7655 17.1891 82.6694 18.0141 82.5157 18.6703C82.362 19.3266 82.1123 19.8422 81.7664 20.2172C81.4206 20.5922 80.9403 20.7797 80.3256 20.7797C79.999 20.7797 79.6099 20.7234 79.1584 20.6109L79.2737 18.8109C79.4274 18.8297 79.5379 18.8391 79.6051 18.8391C79.9413 18.8391 80.1911 18.7219 80.3544 18.4875C80.5273 18.2438 80.6425 17.9391 80.7002 17.5734C80.7578 17.1984 80.801 16.7063 80.8299 16.0969L80.9451 13.0594H87.5732Z"
        fill="#4AA9FF"
      />
      <path
        d="M93.9278 15.4781C94.9364 15.4875 95.7096 15.7125 96.2476 16.1531C96.7855 16.5844 97.0545 17.1984 97.0545 17.9953C97.0545 18.8297 96.7519 19.4813 96.1467 19.95C95.5512 20.4094 94.7106 20.6391 93.6252 20.6391L89.7348 20.625V13.0594H91.9681V15.4781H93.9278ZM93.4234 19.0781C93.8557 19.0875 94.1871 18.9984 94.4177 18.8109C94.6482 18.6234 94.7635 18.3422 94.7635 17.9672C94.7635 17.6016 94.6482 17.3344 94.4177 17.1656C94.1967 16.9969 93.8653 16.9078 93.4234 16.8984L91.9681 16.8844V19.0781H93.4234Z"
        fill="#4AA9FF"
      />
      <path
        d="M102.94 13.5797C103.68 13.5797 104.328 13.7297 104.885 14.0297C105.442 14.3203 105.87 14.7328 106.168 15.2672C106.475 15.8016 106.629 16.4203 106.629 17.1234C106.629 17.8453 106.446 18.4875 106.081 19.05C105.726 19.6031 105.226 20.0344 104.583 20.3438C103.939 20.6438 103.209 20.7937 102.393 20.7937C101.038 20.7937 99.9718 20.3719 99.1937 19.5281C98.4253 18.675 98.041 17.4281 98.041 15.7875C98.041 14.2688 98.3772 13.0594 99.0497 12.1594C99.7317 11.25 100.774 10.6547 102.176 10.3734L105.865 9.65625L106.168 11.5406L102.911 12.1734C102.027 12.3422 101.369 12.6234 100.937 13.0172C100.515 13.4109 100.265 13.9688 100.188 14.6906C100.524 14.3344 100.928 14.0625 101.398 13.875C101.869 13.6781 102.383 13.5797 102.94 13.5797ZM102.407 19.0922C102.983 19.0922 103.444 18.9094 103.79 18.5438C104.146 18.1781 104.323 17.7141 104.323 17.1516C104.323 16.5797 104.146 16.125 103.79 15.7875C103.444 15.45 102.983 15.2812 102.407 15.2812C101.821 15.2812 101.35 15.45 100.995 15.7875C100.639 16.125 100.462 16.5797 100.462 17.1516C100.462 17.7234 100.644 18.1922 101.009 18.5578C101.374 18.9141 101.84 19.0922 102.407 19.0922Z"
        fill="#4AA9FF"
      />
      <path
        d="M115.709 13.0594L112.208 21.0891C111.852 21.9609 111.411 22.575 110.882 22.9313C110.364 23.2875 109.734 23.4656 108.995 23.4656C108.591 23.4656 108.193 23.4047 107.799 23.2828C107.405 23.1609 107.083 22.9922 106.833 22.7766L107.655 21.2156C107.828 21.3656 108.025 21.4828 108.245 21.5672C108.476 21.6516 108.702 21.6938 108.923 21.6938C109.23 21.6938 109.48 21.6188 109.672 21.4688C109.864 21.3281 110.037 21.0891 110.191 20.7516L110.219 20.6813L106.862 13.0594H109.182L111.358 18.1922L113.548 13.0594H115.709Z"
        fill="#4AA9FF"
      />
      <path
        d="M121.228 20.625L119.527 18.3047L117.769 20.625H115.32L118.303 16.8141L115.421 13.0594H117.942L119.585 15.2672L121.271 13.0594H123.663L120.781 16.7578L123.778 20.625H121.228Z"
        fill="#4AA9FF"
      />
      <path
        d="M131.095 14.8594H127.118V20.625H124.885V13.0594H131.095V14.8594Z"
        fill="#4AA9FF"
      />
      <path
        d="M135.297 12.9469C136.498 12.9469 137.42 13.2281 138.064 13.7906C138.708 14.3438 139.029 15.1828 139.029 16.3078V20.625H136.926V19.6828C136.503 20.3859 135.715 20.7375 134.563 20.7375C133.967 20.7375 133.448 20.6391 133.006 20.4422C132.574 20.2453 132.243 19.9734 132.012 19.6266C131.782 19.2797 131.666 18.8859 131.666 18.4453C131.666 17.7422 131.935 17.1891 132.473 16.7859C133.021 16.3828 133.861 16.1813 134.995 16.1813H136.782C136.782 15.7031 136.633 15.3375 136.335 15.0844C136.037 14.8219 135.59 14.6906 134.995 14.6906C134.582 14.6906 134.174 14.7562 133.77 14.8875C133.376 15.0094 133.04 15.1781 132.761 15.3938L131.955 13.8609C132.377 13.5703 132.882 13.3453 133.467 13.1859C134.063 13.0266 134.673 12.9469 135.297 12.9469ZM135.125 19.2609C135.509 19.2609 135.85 19.1766 136.148 19.0078C136.445 18.8297 136.657 18.5719 136.782 18.2344V17.4609H135.24C134.318 17.4609 133.857 17.7563 133.857 18.3469C133.857 18.6281 133.967 18.8531 134.188 19.0219C134.418 19.1813 134.731 19.2609 135.125 19.2609Z"
        fill="#4AA9FF"
      />
      <path
        d="M148.417 13.0594V20.625H146.169V14.8594H143.72L143.648 16.1953C143.609 17.1891 143.513 18.0141 143.359 18.6703C143.206 19.3266 142.956 19.8422 142.61 20.2172C142.264 20.5922 141.784 20.7797 141.169 20.7797C140.843 20.7797 140.454 20.7234 140.002 20.6109L140.117 18.8109C140.271 18.8297 140.382 18.8391 140.449 18.8391C140.785 18.8391 141.035 18.7219 141.198 18.4875C141.371 18.2438 141.486 17.9391 141.544 17.5734C141.602 17.1984 141.645 16.7063 141.674 16.0969L141.789 13.0594H148.417Z"
        fill="#4AA9FF"
      />
      <path
        d="M157.091 14.8594H154.469V20.625H152.235V14.8594H149.613V13.0594H157.091V14.8594Z"
        fill="#4AA9FF"
      />
      <path
        d="M165.502 16.8703C165.502 16.8984 165.487 17.0953 165.458 17.4609H159.594C159.7 17.9297 159.949 18.3 160.343 18.5719C160.737 18.8438 161.227 18.9797 161.813 18.9797C162.216 18.9797 162.572 18.9234 162.879 18.8109C163.196 18.6891 163.489 18.5016 163.758 18.2484L164.954 19.5141C164.224 20.3297 163.158 20.7375 161.755 20.7375C160.881 20.7375 160.108 20.5734 159.436 20.2453C158.763 19.9078 158.244 19.4438 157.879 18.8531C157.514 18.2625 157.332 17.5922 157.332 16.8422C157.332 16.1016 157.51 15.4359 157.865 14.8453C158.23 14.2453 158.725 13.7812 159.349 13.4531C159.983 13.1156 160.689 12.9469 161.467 12.9469C162.226 12.9469 162.913 13.1063 163.528 13.425C164.142 13.7438 164.623 14.2031 164.969 14.8031C165.324 15.3938 165.502 16.0828 165.502 16.8703ZM161.482 14.6062C160.972 14.6062 160.545 14.7469 160.199 15.0281C159.853 15.3094 159.642 15.6937 159.565 16.1813H163.384C163.307 15.7031 163.095 15.3234 162.75 15.0422C162.404 14.7516 161.981 14.6062 161.482 14.6062Z"
        fill="#4AA9FF"
      />
      <path
        d="M171.792 12.9469C172.513 12.9469 173.166 13.1109 173.752 13.4391C174.347 13.7578 174.813 14.2125 175.15 14.8031C175.486 15.3844 175.654 16.0641 175.654 16.8422C175.654 17.6203 175.486 18.3047 175.15 18.8953C174.813 19.4766 174.347 19.9313 173.752 20.2594C173.166 20.5781 172.513 20.7375 171.792 20.7375C170.803 20.7375 170.025 20.4328 169.458 19.8234V23.3531H167.21V13.0594H169.357V13.9313C169.914 13.275 170.726 12.9469 171.792 12.9469ZM171.403 18.9375C171.98 18.9375 172.45 18.75 172.815 18.375C173.19 17.9906 173.377 17.4797 173.377 16.8422C173.377 16.2047 173.19 15.6984 172.815 15.3234C172.45 14.9391 171.98 14.7469 171.403 14.7469C170.827 14.7469 170.351 14.9391 169.977 15.3234C169.612 15.6984 169.429 16.2047 169.429 16.8422C169.429 17.4797 169.612 17.9906 169.977 18.375C170.351 18.75 170.827 18.9375 171.403 18.9375Z"
        fill="#4AA9FF"
      />
      <path
        d="M177.229 13.0594H179.463V17.5594L183.238 13.0594H185.298V20.625H183.065V16.125L179.304 20.625H177.229V13.0594Z"
        fill="#4AA9FF"
      />
      <path
        d="M194.346 13.0594V20.625H192.344V18.4594H190.687L189.217 20.625H186.825L188.597 18.15C188.059 17.9438 187.646 17.6484 187.358 17.2641C187.08 16.8703 186.94 16.3969 186.94 15.8438C186.94 14.9531 187.267 14.2687 187.92 13.7906C188.583 13.3031 189.491 13.0594 190.643 13.0594H194.346ZM190.687 14.8453C190.206 14.8453 189.841 14.9297 189.591 15.0984C189.351 15.2672 189.231 15.5391 189.231 15.9141C189.231 16.2891 189.347 16.5656 189.577 16.7438C189.817 16.9125 190.177 16.9969 190.658 16.9969H192.344V14.8453H190.687Z"
        fill="#4AA9FF"
      />
    </g>
    <defs>
      <clipPath id="clip0_5501_265343">
        <rect width="195" height="30" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
