import { SVGProps } from "react"
import { JSX } from "react/jsx-runtime"

export const LogoMainIcon = (
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) => (
  <svg
    width="299"
    height="46"
    viewBox="0 0 299 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.625 8.625C0.625 4.22097 4.28741 0.625 8.83744 0.625H38.2956C42.8456 0.625 46.508 4.22097 46.508 8.625V37.375C46.508 41.779 42.8456 45.375 38.2956 45.375H8.83744C4.28741 45.375 0.625 41.779 0.625 37.375V8.625Z"
      fill="#4AA9FF"
      stroke="#4AA9FF"
      stroke-width="1.25"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M38.2956 1.79688H8.83744C4.97349 1.79688 1.84113 4.85393 1.84113 8.625V37.375C1.84113 41.1461 4.97349 44.2031 8.83744 44.2031H38.2956C42.1595 44.2031 45.2919 41.1461 45.2919 37.375V8.625C45.2919 4.85393 42.1595 1.79688 38.2956 1.79688ZM8.83744 0C3.95666 0 0 3.86154 0 8.625V37.375C0 42.1385 3.95666 46 8.83744 46H38.2956C43.1763 46 47.133 42.1385 47.133 37.375V8.625C47.133 3.86154 43.1763 0 38.2956 0H8.83744Z"
      fill="#4AA9FF"
    />
    <path
      d="M11.7832 11.498H16.4965V16.098H21.2098V20.698H25.9231V25.298L21.2098 25.298V29.898L16.4965 29.898V34.498H11.7832V25.298L16.4965 25.298V20.698H11.7832V11.498Z"
      fill="white"
    />
    <path
      d="M25.9231 29.898V25.298L30.6364 25.298V29.898L25.9231 29.898Z"
      fill="white"
    />
    <path d="M30.6364 29.898H35.3497V34.498H30.6364V29.898Z" fill="white" />
    <path
      d="M25.9231 29.898L21.2098 29.898V34.498H25.9231L25.9231 29.898Z"
      fill="white"
    />
    <path
      d="M30.6364 25.298L30.6364 20.698H25.9231L25.9231 16.098H21.2098V11.498H30.6364V16.098H35.3497V25.298H30.6364Z"
      fill="white"
    />
    <path
      d="M73.4393 31.6254L73.4173 22.5907L68.866 30.0298H67.2752L62.7239 22.7632V31.6254H59.3657V16.5316H62.3263L68.1148 25.9113L73.8149 16.5316H76.7755L76.7976 31.6254H73.4393Z"
      fill="#4AA9FF"
    />
    <path
      d="M85.9419 31.7979C84.6899 31.7979 83.5632 31.5463 82.5616 31.0432C81.5747 30.5257 80.8015 29.8141 80.2418 28.9085C79.6821 28.0029 79.4022 26.9751 79.4022 25.8251C79.4022 24.6751 79.6821 23.6473 80.2418 22.7416C80.8015 21.836 81.5747 21.1316 82.5616 20.6285C83.5632 20.111 84.6899 19.8523 85.9419 19.8523C87.1939 19.8523 88.3133 20.111 89.3001 20.6285C90.287 21.1316 91.0603 21.836 91.62 22.7416C92.1797 23.6473 92.4595 24.6751 92.4595 25.8251C92.4595 26.9751 92.1797 28.0029 91.62 28.9085C91.0603 29.8141 90.287 30.5257 89.3001 31.0432C88.3133 31.5463 87.1939 31.7979 85.9419 31.7979ZM85.9419 29.0379C86.8256 29.0379 87.5474 28.7504 88.1071 28.1754C88.6815 27.586 88.9687 26.8026 88.9687 25.8251C88.9687 24.8476 88.6815 24.0713 88.1071 23.4963C87.5474 22.907 86.8256 22.6123 85.9419 22.6123C85.0582 22.6123 84.3291 22.907 83.7546 23.4963C83.1802 24.0713 82.893 24.8476 82.893 25.8251C82.893 26.8026 83.1802 27.586 83.7546 28.1754C84.3291 28.7504 85.0582 29.0379 85.9419 29.0379Z"
      fill="#4AA9FF"
    />
    <path
      d="M107.98 28.8654V34.1266H104.776V31.6254H96.425V34.1266H93.2214V28.8654H93.7075C94.4439 28.851 94.9521 28.427 95.2319 27.5932C95.5118 26.7595 95.6885 25.5735 95.7622 24.0354L95.9168 20.0248H106.279V28.8654H107.98ZM98.8774 24.2726C98.8332 25.4513 98.7374 26.4145 98.5901 27.162C98.4576 27.9095 98.1998 28.4773 97.8169 28.8654H102.832V22.7848H98.9436L98.8774 24.2726Z"
      fill="#4AA9FF"
    />
    <path
      d="M122.057 20.0248L116.689 32.337C116.144 33.6738 115.466 34.6154 114.656 35.1616C113.861 35.7079 112.896 35.981 111.762 35.981C111.143 35.981 110.532 35.8876 109.928 35.7007C109.324 35.5138 108.831 35.2551 108.448 34.9245L109.707 32.531C109.972 32.761 110.274 32.9407 110.613 33.0701C110.966 33.1995 111.312 33.2641 111.651 33.2641C112.123 33.2641 112.506 33.1491 112.8 32.9191C113.095 32.7035 113.36 32.337 113.595 31.8195L113.64 31.7116L108.492 20.0248H112.049L115.385 27.8951L118.743 20.0248H122.057Z"
      fill="#4AA9FF"
    />
    <path
      d="M134.279 20.0248V31.6254H130.832V22.7848H127.076L126.966 24.8332C126.907 26.357 126.76 27.622 126.524 28.6282C126.288 29.6345 125.905 30.4251 125.375 31.0001C124.845 31.5751 124.108 31.8626 123.166 31.8626C122.665 31.8626 122.069 31.7763 121.376 31.6038L121.553 28.8438C121.789 28.8726 121.958 28.887 122.061 28.887C122.577 28.887 122.96 28.7073 123.21 28.3479C123.475 27.9741 123.652 27.507 123.74 26.9463C123.829 26.3713 123.895 25.6166 123.939 24.6823L124.116 20.0248H134.279Z"
      fill="#4AA9FF"
    />
    <path
      d="M144.023 23.7335C145.569 23.7479 146.755 24.0929 147.58 24.7685C148.404 25.4298 148.817 26.3713 148.817 27.5932C148.817 28.8726 148.353 29.8716 147.425 30.5904C146.512 31.2948 145.223 31.647 143.559 31.647L137.593 31.6254V20.0248H141.018V23.7335H144.023ZM143.249 29.2535C143.912 29.2679 144.42 29.1313 144.774 28.8438C145.127 28.5563 145.304 28.1251 145.304 27.5501C145.304 26.9895 145.127 26.5798 144.774 26.321C144.435 26.0623 143.927 25.9257 143.249 25.9113L141.018 25.8898V29.2535H143.249Z"
      fill="#4AA9FF"
    />
    <path
      d="M157.841 20.8226C158.976 20.8226 159.97 21.0526 160.824 21.5126C161.678 21.9582 162.334 22.5907 162.79 23.4101C163.262 24.2295 163.497 25.1782 163.497 26.2563C163.497 27.3632 163.217 28.3479 162.658 29.2104C162.113 30.0585 161.347 30.7198 160.36 31.1941C159.373 31.6541 158.254 31.8841 157.002 31.8841C154.925 31.8841 153.29 31.2373 152.097 29.9435C150.919 28.6354 150.33 26.7235 150.33 24.2079C150.33 21.8791 150.845 20.0248 151.876 18.6448C152.922 17.2504 154.52 16.3376 156.67 15.9063L162.326 14.8066L162.79 17.696L157.797 18.6663C156.442 18.9251 155.433 19.3563 154.77 19.9601C154.122 20.5638 153.739 21.4191 153.621 22.526C154.137 21.9798 154.756 21.5629 155.477 21.2754C156.199 20.9735 156.987 20.8226 157.841 20.8226ZM157.024 29.2751C157.908 29.2751 158.615 28.9948 159.145 28.4341C159.69 27.8735 159.962 27.162 159.962 26.2995C159.962 25.4226 159.69 24.7254 159.145 24.2079C158.615 23.6904 157.908 23.4316 157.024 23.4316C156.125 23.4316 155.404 23.6904 154.859 24.2079C154.314 24.7254 154.041 25.4226 154.041 26.2995C154.041 27.1763 154.321 27.8951 154.881 28.4557C155.441 29.002 156.155 29.2751 157.024 29.2751Z"
      fill="#4AA9FF"
    />
    <path
      d="M177.421 20.0248L172.052 32.337C171.507 33.6738 170.83 34.6154 170.019 35.1616C169.224 35.7079 168.259 35.981 167.125 35.981C166.507 35.981 165.895 35.8876 165.291 35.7007C164.688 35.5138 164.194 35.2551 163.811 34.9245L165.07 32.531C165.336 32.761 165.638 32.9407 165.976 33.0701C166.33 33.1995 166.676 33.2641 167.015 33.2641C167.486 33.2641 167.869 33.1491 168.164 32.9191C168.458 32.7035 168.723 32.337 168.959 31.8195L169.003 31.7116L163.855 20.0248H167.412L170.749 27.8951L174.107 20.0248H177.421Z"
      fill="#4AA9FF"
    />
    <path
      d="M185.882 31.6254L183.275 28.0676L180.58 31.6254H176.824L181.397 25.782L176.979 20.0248H180.845L183.364 23.4101L185.949 20.0248H189.616L185.197 25.6957L189.793 31.6254H185.882Z"
      fill="#4AA9FF"
    />
    <path
      d="M201.012 22.7848H194.914V31.6254H191.49V20.0248H201.012V22.7848Z"
      fill="#4AA9FF"
    />
    <path
      d="M207.456 19.8523C209.297 19.8523 210.711 20.2835 211.698 21.146C212.685 21.9941 213.178 23.2807 213.178 25.0057V31.6254H209.953V30.1807C209.304 31.2588 208.097 31.7979 206.329 31.7979C205.416 31.7979 204.621 31.647 203.943 31.3451C203.28 31.0432 202.772 30.6263 202.419 30.0945C202.065 29.5626 201.888 28.9588 201.888 28.2832C201.888 27.2051 202.301 26.357 203.126 25.7388C203.965 25.1207 205.254 24.8116 206.992 24.8116H209.732C209.732 24.0785 209.503 23.5179 209.047 23.1298C208.59 22.7273 207.905 22.526 206.992 22.526C206.359 22.526 205.733 22.6266 205.114 22.8279C204.51 23.0148 203.995 23.2735 203.568 23.6041L202.33 21.2538C202.978 20.8082 203.752 20.4632 204.65 20.2188C205.563 19.9745 206.499 19.8523 207.456 19.8523ZM207.191 29.5338C207.78 29.5338 208.303 29.4045 208.76 29.1457C209.216 28.8726 209.54 28.4773 209.732 27.9598V26.7738H207.368C205.954 26.7738 205.247 27.2266 205.247 28.1323C205.247 28.5635 205.416 28.9085 205.755 29.1673C206.108 29.4116 206.587 29.5338 207.191 29.5338Z"
      fill="#4AA9FF"
    />
    <path
      d="M227.573 20.0248V31.6254H224.126V22.7848H220.37L220.26 24.8332C220.201 26.357 220.053 27.622 219.818 28.6282C219.582 29.6345 219.199 30.4251 218.669 31.0001C218.139 31.5751 217.402 31.8626 216.459 31.8626C215.959 31.8626 215.362 31.7763 214.67 31.6038L214.847 28.8438C215.082 28.8726 215.252 28.887 215.355 28.887C215.87 28.887 216.253 28.7073 216.504 28.3479C216.769 27.9741 216.946 27.507 217.034 26.9463C217.122 26.3713 217.189 25.6166 217.233 24.6823L217.41 20.0248H227.573Z"
      fill="#4AA9FF"
    />
    <path
      d="M240.873 22.7848H236.852V31.6254H233.428V22.7848H229.407V20.0248H240.873V22.7848Z"
      fill="#4AA9FF"
    />
    <path
      d="M253.769 25.8682C253.769 25.9113 253.747 26.2132 253.703 26.7738H244.711C244.873 27.4926 245.256 28.0604 245.86 28.4773C246.464 28.8941 247.215 29.1026 248.113 29.1026C248.732 29.1026 249.277 29.0163 249.748 28.8438C250.234 28.657 250.683 28.3695 251.096 27.9813L252.93 29.922C251.81 31.1726 250.175 31.7979 248.025 31.7979C246.684 31.7979 245.499 31.5463 244.468 31.0432C243.437 30.5257 242.641 29.8141 242.082 28.9085C241.522 28.0029 241.242 26.9751 241.242 25.8251C241.242 24.6895 241.515 23.6688 242.06 22.7632C242.619 21.8432 243.378 21.1316 244.335 20.6285C245.307 20.111 246.39 19.8523 247.583 19.8523C248.747 19.8523 249.8 20.0966 250.742 20.5854C251.685 21.0741 252.421 21.7785 252.952 22.6985C253.497 23.6041 253.769 24.6607 253.769 25.8682ZM247.605 22.3966C246.824 22.3966 246.169 22.6123 245.639 23.0435C245.108 23.4748 244.784 24.0641 244.667 24.8116H250.521C250.404 24.0785 250.08 23.4963 249.549 23.0651C249.019 22.6195 248.371 22.3966 247.605 22.3966Z"
      fill="#4AA9FF"
    />
    <path
      d="M263.415 19.8523C264.519 19.8523 265.521 20.1038 266.419 20.607C267.333 21.0957 268.047 21.7929 268.563 22.6985C269.078 23.5898 269.336 24.632 269.336 25.8251C269.336 27.0182 269.078 28.0676 268.563 28.9732C268.047 29.8645 267.333 30.5616 266.419 31.0648C265.521 31.5535 264.519 31.7979 263.415 31.7979C261.898 31.7979 260.705 31.3307 259.836 30.3963V35.8085H256.389V20.0248H259.681V21.3616C260.535 20.3554 261.78 19.8523 263.415 19.8523ZM262.818 29.0379C263.702 29.0379 264.424 28.7504 264.983 28.1754C265.558 27.586 265.845 26.8026 265.845 25.8251C265.845 24.8476 265.558 24.0713 264.983 23.4963C264.424 22.907 263.702 22.6123 262.818 22.6123C261.934 22.6123 261.205 22.907 260.631 23.4963C260.071 24.0713 259.791 24.8476 259.791 25.8251C259.791 26.8026 260.071 27.586 260.631 28.1754C261.205 28.7504 261.934 29.0379 262.818 29.0379Z"
      fill="#4AA9FF"
    />
    <path
      d="M271.751 20.0248H275.176V26.9248L280.964 20.0248H284.124V31.6254H280.699V24.7254L274.933 31.6254H271.751V20.0248Z"
      fill="#4AA9FF"
    />
    <path
      d="M297.998 20.0248V31.6254H294.927V28.3048H292.386L290.132 31.6254H286.465L289.182 27.8304C288.358 27.5141 287.724 27.0613 287.282 26.472C286.855 25.8682 286.642 25.1423 286.642 24.2941C286.642 22.9285 287.142 21.8791 288.144 21.146C289.16 20.3985 290.552 20.0248 292.32 20.0248H297.998ZM292.386 22.7632C291.65 22.7632 291.09 22.8926 290.707 23.1513C290.339 23.4101 290.155 23.827 290.155 24.402C290.155 24.977 290.331 25.401 290.685 25.6741C291.053 25.9329 291.605 26.0623 292.342 26.0623H294.927V22.7632H292.386Z"
      fill="#4AA9FF"
    />
  </svg>
)
